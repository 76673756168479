import ApiInstance from "../configs/ApiInstance"

const SaveUserData = async (email, given_name, family_name, gender, nickname,bio, profileKeyId, selectedCategories) => {
    try {

        const profileImageKey = profileKeyId;
        const role = "USER";

        const response = await ApiInstance.userMicroService.post("", {
            given_name,
            family_name,
            gender,
            nickname,
            bio,
            email,
            profileImageKey,
            role,
            favourites: selectedCategories
        });
        const data = response.data;
        return data
    } catch (error) {
        console.error("Error", error);
        throw error;
    }
}

export default SaveUserData;