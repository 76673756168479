import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from "./components/SignUp";
import ConfirmUser from "./components/ConfirmUser";

function Screen() {
    return (
    <Router>
        <Routes>
            <Route path="/signup" element={<SignUp/>} ></Route>
            <Route path="/signup/confirmuser" element={<ConfirmUser/>} ></Route>
        </Routes>
    </Router>
    )
}

export default Screen;