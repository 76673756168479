import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { navigateToUrl } from 'single-spa';

function ConfirmUser() {
  const handleGoHome = () => {
    navigateToUrl('/');
  };

  return (
    <Container
    maxWidth="lg"
    sx={{
      marginTop: 35,
    }}
  >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundSize: 'cover',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" fontFamily={'serif'} >
        Thank you for registering with Ceylon Diaries! Please check your email to confirm your account.
        </Typography>
        <Button
          variant="contained"
          onClick={handleGoHome}
          sx={{ 
            mt: 5,
            background: 'black',
            "&:hover": {
              background: 'black',
            }
          }}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}

export default ConfirmUser;
