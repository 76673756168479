import React, { useState, useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignUpService from '../services/SignUpService';
import SaveUserData from '../services/SaveUserService';
import { navigateToUrl } from 'single-spa';
import UploadImage from '../services/UploadImage';
import { Alert, FormControl, InputLabel, MenuItem, Select, Collapse, IconButton, Chip, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../styles/styles.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const defaultTheme = createTheme();

const categories = [
    'ENTERTAINMENT',
    'SPORT',
    'FASHION',
    'TRAVEL',
    'FOOD',
    'TECHNOLOGY',
    'HEALTH',
    'LIFESTYLE',
    'BUSINESS',
    'EDUCATION',
    'ENVIRONMENT',
    'CULTURE',
    'HISTORY',
    'PETS',
    'BOOKS',
    'GAMING'
];

function SignUp() {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [bio, setBio] = useState('');
    const [fileError, setFileError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);


    const fileInputRef = useRef(null);
    const charLimit = 300;

    const handleCategoryChange = (event) => {
        const { value } = event.target;
        if (value.length <= 4) {
            setSelectedCategories(value);
        } else {
            showAlert({ message: "You can select up to 4 categories only." });
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setFileError('');
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const showAlert = (error) => {
        setOpen(true);
        setErrorMessage(error.message);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        formData.append('profileImage', file);

        selectedCategories.forEach((category) => {
            formData.append('categories', category);
        });

        const given_name = formData.get('given_name');
        const family_name = formData.get('family_name');
        const email = formData.get('email');
        const gender = formData.get('gender');
        const bio = formData.get('Bio');
        const nickname = formData.get('nickname');
        const password = formData.get('password');

        console.log("Form Data:", {
            given_name,
            family_name,
            email,
            gender,
            bio,
            nickname,
            password,
            selectedCategories,
            file
        });

        if (!given_name) {
            showAlert({ message: "Please provide your first name." });
            return;
        }
        if (!family_name) {
            showAlert({ message: "Please provide your last name." });
            return;
        }
        if (!email) {
            showAlert({ message: "Please provide your email address." });
            return;
        }
        if (!gender) {
            showAlert({ message: "Please select ." });
            return;
        }
        if (!bio) {
            showAlert({ message: "Please provide a bio." });
            return;
        }
        if (!nickname) {
            showAlert({ message: "Please provide your username." });
            return;
        }

        if (!file) {
            setFileError("Please select a file.");
            return;
        }

        if (selectedCategories.length !== 4) {
            return showAlert({ message: "Please select exactly 4 categories." });
        }


        try {
            setErrorMessage('');
            await SignUpService(email, password, given_name, family_name, gender, nickname);
            const imageUploadResponse = await UploadImage(formData);
            const profileKeyId = imageUploadResponse.profileImageKey;
            await SaveUserData(email, given_name, family_name, gender, nickname, bio, profileKeyId, selectedCategories);
            navigateToUrl('/signup/confirmuser');
        } catch (error) {
            if (error.message.includes("Value at 'username' failed to satisfy constraint")) {
                showAlert({ message: "Username contains invalid characters. Please use only letters, numbers, and allowed symbols. It cannot contain spaces." });
            } else if (error.message.includes("Value at 'password' failed to satisfy constraint")) {
                showAlert({ message: "Password must be at least 8 characters long and include a combination of letters, numbers, and special characters" });

            } else {
                showAlert(error);
            }
        }
    };

    const handleBioChange = (event) => {
        const newBio = event.target.value;
        if (newBio.length <= charLimit) {
            setBio(newBio);
        } else {
            setOpen(true);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" sx={{ maxWidth: { xs: 'xs', md: 'md'} }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: { xs: 8, sm: 10, md: 12 },
                        marginBottom: { xs: 6, sm: 9 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{
                            m: 1,
                            width: 64,
                            height: 64,
                            backgroundColor: 'black',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            borderRadius: '50%',
                            backgroundSize: 'cover',
                        }}
                    >
                        <AccountCircleIcon />
                    </Avatar>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="given_name"
                                    required
                                    fullWidth
                                    id="given_name"
                                    label="First Name"
                                    className="gradient-textfield"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="family_name"
                                    label="Last Name"
                                    name="family_name"
                                    autoComplete="family_name"
                                    className="gradient-textfield"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    className="gradient-textfield"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="gender-label">Gender</InputLabel>
                                    <Select
                                        labelId="gender-label"
                                        id="gender"
                                        name="gender"
                                        label="Gender"
                                        autoComplete="gender"
                                        className="gradient-select"
                                    >
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <Box>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        id="Bio"
                                        label="Bio"
                                        name="Bio"
                                        className="gradient-textfield"
                                        onChange={handleBioChange}
                                        inputProps={{ maxLength: charLimit }}
                                    />
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {bio.length}/{charLimit} characters
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth required>
                                    <InputLabel id="categories-label">Favorite Categories</InputLabel>
                                    <Select
                                        labelId="categories-label"
                                        id="categories"
                                        label="Favorite Categories"
                                        className="gradient-select"
                                        name="categories"
                                        multiple
                                        value={selectedCategories}
                                        onChange={handleCategoryChange}
                                    >
                                        {categories.map((category) => (
                                            <MenuItem key={category} value={category}>
                                                {category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>Select up to 4 categories</FormHelperText>
                                </FormControl>

                                <Box
                                    sx={{
                                        mt: 2,
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                    }}
                                >
                                    {selectedCategories.map((value) => (
                                        <Chip key={value} label={value} sx={{ backgroundColor: 'black', color: '#fff' }} />
                                    ))}
                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nickname"
                                    label="UserName"
                                    name="nickname"
                                    autoComplete="nickname"
                                    className="gradient-textfield"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    className="gradient-textfield"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    onClick={handleButtonClick}
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        fontWeight: 550,
                                        letterSpacing: 2,
                                        background: 'black',
                                        "&:hover": {
                                            background: 'black',
                                        }
                                    }}
                                >
                                    Upload Profile Image
                                </Button>
                                <input
                                    type="file"
                                    id="profileImage"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                {fileError && <Typography color="error">{fileError}</Typography>}
                                {fileName && (
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        Selected file: {fileName}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                fontWeight: 550,
                                letterSpacing: 2,
                                background: 'black',
                                "&:hover": {
                                    background: 'black',
                                }
                            }}
                        >
                            Create an Account
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/signin" variant="body2" sx={{ textDecoration: 'none', color: 'black'}}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ width: '100%', mt: 5 }}>
                        <Collapse in={open}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {errorMessage}
                            </Alert>
                        </Collapse>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default SignUp;
