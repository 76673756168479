import ApiInstance from "../configs/ApiInstance"

const UploadImage = async (file) => {
    try {
        const response = await ApiInstance.userAwsImageService.post('/upload', file);
        const imageId = response.data;
        return imageId;
    } catch (error) {
        console.error('Image upload failed:', error);
        throw error;
    }
};

export default UploadImage