import axios from "axios"
import configs from "./endpoints.json"

const userAwsService = axios.create({
    baseURL: `${configs.userAwsService}`,
    headers: {
        'Content-Type': 'application/json',
      },
})

const userAwsImageService = axios.create({
    baseURL: `${configs.userAwsService}`,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

const userMicroService = axios.create({
    baseURL: `${configs.userMicroService}`,
    headers: {
        'Content-Type': 'application/json',
      },
})

const ApiInstance = {
    userAwsService,
    userMicroService,
    userAwsImageService
}

export default ApiInstance;