import ApiInstance from '../configs/ApiInstance';

async function SignUpService(email, password, given_name, family_name, gender, nickname) {
  try {
    const response = await ApiInstance.userAwsService.post("/signup" , {
        given_name,
        family_name,
        gender,
        nickname,
        email,
        password
    });

    const data = response.data;


    return data;
  } catch (error) {
    if (error.response) {

      console.error('Error:', error.response.data);
      throw new Error(error.response.data || 'There was a problem with the signup request');
    } else if (error.request) {
      console.error('Error:', error.request);
      throw new Error('No response received from the server');
    } else {
      console.error('Error:', error.message);
      throw new Error(error.message);
    }
  }
}

export default SignUpService;
